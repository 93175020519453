import type React from 'react'

import { CommerceToolsApi } from '@grandvisionhq/commercetools'
import type { ProductDetailProvider } from '@grandvisionhq/products'

type SpecificationConfiguration = React.ComponentPropsWithoutRef<
  typeof ProductDetailProvider
>['specificationConfiguration']

export function getSpecifications(productType: string): SpecificationConfiguration | undefined {
  const commonSpecifications = [
    {
      labelKey: 'products.specifications.erpId',
      attribute: 'erpId',
    },
    {
      labelKey: 'products.specifications.gender',
      attribute: 'gender',
      isLocalised: true,
      isSet: true,
    },
    {
      labelKey: 'products.specifications.frameMaterial',
      attribute: 'frameMaterial',
      isLocalised: true,
    },
    {
      labelKey: 'products.specifications.frameColor',
      attribute: 'frameColor',
      isLocalised: true,
    },
    {
      labelKey: 'products.specifications.frameShape',
      attribute: 'frameShape',
      isLocalised: true,
    },
    {
      labelKey: 'products.specifications.roadworthiness',
      attribute: 'roadworthiness',
      labelPrefix: 'products.specifications.roadworthiness.',
    },
  ]

  if (CommerceToolsApi.isFrames(productType)) {
    return commonSpecifications
  }

  if (CommerceToolsApi.isSunglasses(productType)) {
    return [
      ...commonSpecifications,
      {
        labelKey: 'products.specifications.glazeable',
        attribute: 'glazeble',
      },
      {
        labelKey: 'products.specifications.polarized',
        attribute: 'polarized',
      },
    ]
  }
  if (CommerceToolsApi.isSolutions(productType)) {
    return [
      ...commonSpecifications,
      {
        labelKey: 'products.solutions.lensTypeTarget',
        attribute: 'lensTypeTarget',
        isLocalised: true,
      },
      {
        labelKey: 'products.solutions.caseIncluded',
        attribute: 'caseIncluded',
      },
      {
        labelKey: 'products.solutions.minimumCycleTime',
        attribute: 'minimumCycleTime',
        isLocalised: true,
      },
      {
        labelKey: 'products.solutions.preservativeFree',
        attribute: 'preservativeFree',
      },
      {
        labelKey: 'products.solutions.maxTimeFrameInLensCase',
        attribute: 'maxTimeFrameInLensCase',
        isLocalised: true,
      },
      {
        labelKey: 'products.solutions.expirationAfterOpening',
        attribute: 'expirationAfterOpening',
        isLocalised: true,
      },
    ]
  }
}
